<template>
  <div>
    <div class="staticHero">
      <v-img src="../assets/images/AboutHero.png" style="max-height: 400px"> </v-img>
    </div>
    <div class="block">
      <v-container>
        <h2>{{ $t("landingFAQ") }}</h2>
        <div v-for="(section, k) in items" :key="k" class="pb-5">
          <h3 class="pt-5 pb-3" v-if="k > 0 && $te(sectionTitle[k - 1])">{{ $t(sectionTitle[k - 1]) }}</h3>
          <v-expansion-panels accordion flat v-if="section.length > 0">
            <v-expansion-panel
              :class="{ 'elevation-0': true, notLastPanel: i !== section.length - 1 }"
              v-for="(item, i) in section"
              :key="i"
              style=""
            >
              <v-expansion-panel-header class="py-2" v-if="$te(item.title)"
                ><faq-text :path="item.title" tag="h3"></faq-text
              ></v-expansion-panel-header>
              <v-expansion-panel-content class="py-0">
                <div class="pb-5" v-if="youtubeLink(item.youtube)">
                  <iframe
                    height="315"
                    :src="youtubeLink(item.youtube)"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    style="width: 560px; max-width: 90vw"
                  ></iframe>
                </div>
                <faq-text :path="item.body" tag="p"></faq-text
              ></v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import Landing from "@/layouts/Landing";
import faqText from "../components/Common/faqText.vue";

export default {
  components: { faqText },
  name: "Questions",
  data: () => {
    return {
      items: [],
      questionSplit: [0, 7, 13, 28, 44, 51, 67]
    };
  },
  computed: {
    sectionTitle() {
      let sections = [
        "faq.sectionA",
        "faq.sectionB",
        "faq.sectionC",
        "faq.sectionD",
        "faq.sectionE",
        "faq.sectionF",
        "faq.sectionG"
      ];
      return sections;
    }
  },
  methods: {
    youtubeLink(translation) {
      const youtubeLink = this.$t(translation);
      if (youtubeLink && youtubeLink !== "NONE") {
        const id = this.youtube_parser(youtubeLink);

        const youtubeEmbed = `https://www.youtube-nocookie.com/embed/${id}`;
        return youtubeEmbed;
      } else {
        return null;
      }
    },
    generateItems() {
      let pad = (n, width, z) => {
        z = z || "0";
        n = n + "";
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      };
      let sections = [
        { label: "sectionA", questions: 8 },
        { label: "sectionB", questions: 6 },
        { label: "sectionC", questions: 15 },
        { label: "sectionD", questions: 16 },
        { label: "sectionE", questions: 7 },
        { label: "sectionF", questions: 7 },
        { label: "sectionG", questions: 8 }
      ];
      const questions = sections.map((section) => {
        const q = `faq.question.${section.label}.q`;
        const ans = `faq.answer.${section.label}.q`;
        const youtube = `faq.youtube.${section.label}.q`;
        const id = `faq.${section.label}.q`;
        const number = Array.from(Array(section.questions).keys());

        const result = number.map((num) => {
          return {
            title: q.concat(pad(num + 1, 3, "0")),
            body: ans.concat(pad(num + 1, 3, "0")),
            id: id.concat(pad(num + 1, 3, "0")),
            youtube: youtube.concat(pad(num + 1, 3, "0"))
          };
        });
        return result;
      });

      this.items = questions;
    },
    youtube_parser(url) {
      var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i; //eslint-disable-line
      var match = url.match(regExp);
      return match && match[1].length == 11 ? match[1] : false;
    }
  },
  created() {
    this.$emit(`update:layout`, Landing);
    this.generateItems();
  }
};
</script>

<style scoped>
.v-expansion-panel-header {
  padding: 0px;
}

.v-expansion-panel-content {
  padding: 0px;
}

.notLastPanel {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}
</style>
