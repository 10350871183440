<template>
  <i18n
    :path="path"
    :tag="tag"
    :style="{
      color: tag === 'h3' ? '#8BB35D' : '#7e8085',
      'font-weight': tag === 'h3' ? '600' : '400',
      'font-size': tag === 'h3' ? '20px' : '16px'
    }"
  >
    <template v-slot:tectechain>
      <strong style="text-transform: uppercase">{{ $t("tectechainName") }}</strong>
    </template>
    <template v-slot:profileBasicMemberUpgrade>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("profileBasicMemberUpgrade") }}</v-btn>
    </template>
    <template v-slot:collectionCreate>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("collectionCreate") }}</v-btn>
    </template>
    <template v-slot:createSave>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("createSave") }}</v-btn>
    </template>
    <template v-slot:collectionEdit>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("collectionEdit") }}</v-btn>
    </template>
    <template v-slot:collectionPrivate>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("collectionPrivate") }}</v-btn>
    </template>
    <template v-slot:collectionDelete>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("collectionDelete") }}</v-btn>
    </template>
    <template v-slot:artworkChat>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("artworkChat") }}</v-btn>
    </template>
    <template v-slot:collectionBazaar>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("collectionBazaar") }}</v-btn>
    </template>
    <template v-slot:viewPublish>
      <v-btn rounded depressed color="primary" small :ripple="false"> {{ $t("viewPublish") }}</v-btn>
    </template>
    <template v-slot:learnMore>
      <a href="https://www.youtube.com/channel/UCtaEVTUuOqgTq2bYV3fhpeQ" target="_blank">{{
        $t("landingLearnMore")
      }}</a>
    </template>
    <template v-slot:lineBreak>
      <br />
    </template>
  </i18n>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: "p"
    }
  }
};
</script>
